<template>
  <base-dialog
    ref="app-create-signature-profile-base-dialog"
    :is-dialog-visible="isDialogVisible"
    max-dialog-width="900px"
    title="Create Signature Profile"
    dialog-image="mdi-receipt-text-plus-outline"
  >
    <template v-slot:content>
      <v-row dense>
        <v-row ref="app-create-signature-profile-dialog-customer-representative-select" v-if="!isNewCustomerRepresentative">
          <v-col>
            <v-select
              ref="customer-representative-select"
              :menu-props="{ bottom: true, offsetY: true }"
              :items="customerRepresentatives"
              :value="mutableCurrentCustomerRepresentative"
              :item-disabled="item => item.hasSignatureProfile"
              @change="setCurrentCustomerRepresentative"
              label="Customer Representative"
              item-text="name"
              item-value="customerRepresentativeId"
              outlined
              return-object
              dense
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row ref="app-create-signature-profile-dialog-customer-representative-name-text-fields" v-else>
          <v-col>
            <v-text-field
              ref="app-create-signature-profile-dialog-first-name-text"
              class="me-2"
              label="First Name *"
              v-model="firstName"
              autocomplete="null"
              :rules="isRequiredRules"
              counter="50"
              clearable
              outlined
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              ref="app-create-signature-profile-dialog-last-name-text"
              class="ms-2"
              label="Last Name *"
              v-model="lastName"
              :rules="isRequiredRules"
              autocomplete="null"
              counter="50"
              clearable
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-row>
      <v-row>
        <v-col class="mb-2">
          <v-divider class="mb-2 mt-n2" />

          By signing this Electronic Signature Acknowledgement, I agree that my electronic signature and initials are the legally binding equivalent to my handwritten signature when used throughout this document.
        </v-col>
      </v-row>

      <v-row dense>
        <v-col class="ma-0 pa-0">
          <div class="ma-0 pa-0">
            <app-signature-pad ref="signature-pad" class="ma-0 pa-0" @on-clear-signature-data="handleClearSignatureData" @on-save-signature-data="handleSaveSignatureData" />
          </div>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-btn ref="cancel-signature-button" class="pa-4" color="primary" @click="cancelSignatureProfile" text rounded ripple>Cancel</v-btn>
      <v-btn
        ref="save-signature-button"
        class="pa-4"
        color="primary"
        @click="saveSignatureProfile"
        text
        rounded
        ripple
        :loading="isLoading"
        :disabled="isSaveSignatureProfileDisabled"
        >Apply</v-btn
      >
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog.vue"
import AppSignaturePad from "@/components/AppSignaturePad.vue"

export default {
  name: "AppCreateSignatureProfileDialog",
  components: { AppSignaturePad, BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    customerRepresentatives: {
      type: Array,
      default: () => []
    },
    currentCustomerRepresentative: {
      type: Object,
      default: () => ({})
    },
    isNewCustomerRepresentative: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isRequiredRules: [value => !!value || "Is required"],
      firstName: "",
      lastName: "",
      signatureData: "",
      hasSavedSignatureData: false,
      mutableCurrentCustomerRepresentative: {}
    }
  },
  computed: {
    isApplyDisabled() {
      return !this.firstName || !this.lastName || !this.hasSavedSignatureData
    },
    isSaveSignatureProfileDisabled() {
      return this.isLoading || this.isApplyDisabled
    }
  },
  methods: {
    setCurrentCustomerRepresentative(customerRepresentative) {
      this.mutableCurrentCustomerRepresentative = customerRepresentative
      this.firstName = customerRepresentative.firstName
      this.lastName = customerRepresentative.lastName
      this.$refs["signature-pad"].clear()
      this.handleClearSignatureData()
    },
    handleClearSignatureData() {
      this.signatureData = ""
      this.hasSavedSignatureData = false
    },
    handleSaveSignatureData(data) {
      this.signatureData = data.data
      this.hasSavedSignatureData = true
    },
    saveSignatureProfile() {
      this.$emit("on-save-signature-profile", this.firstName, this.lastName, this.signatureData, this.mutableCurrentCustomerRepresentative)
    },
    cancelSignatureProfile() {
      this.$emit("on-cancel-signature-profile")
    }
  },
  created() {
    // set first name and last name to customer representative if exists
    this.mutableCurrentCustomerRepresentative = this.currentCustomerRepresentative
    if (!this.isNewCustomerRepresentative) {
      this.firstName = this.currentCustomerRepresentative.firstName
      this.lastName = this.currentCustomerRepresentative.lastName
    }
  }
}
</script>
